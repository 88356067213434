import React, { useEffect, useRef } from 'react';

function NewLoadingScreen() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let circles = []; // Array to store circle data

    const drawCircles = () => {
      const { width, height } = canvas.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;

      const radius = circles.length * 10 + 15; // Calculate radius based on the number of circles
      const color = getRandomColor(); // Get a random color for each circle

      // Add the circle data to the array
      circles.push({ radius, color });

      // Reverse the order of the circles array
      circles.reverse();

      // Draw all circles
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
      circles.forEach(circle => {
        ctx.beginPath();
        ctx.arc(canvas.width / 2, canvas.height / 2, circle.radius, 0, 2 * Math.PI);
        ctx.fillStyle = circle.color;
        ctx.fill();
      });

      // Reverse the order of the circles array again to maintain the original order
      circles.reverse();

      if (radius > height / 2 + 10) {
        circles = []; // Reset the circles array to start drawing from the beginning
      }
    };

    const intervalId = setInterval(drawCircles, 55);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function getRandomColor() {
    var color1 = '#40BCF4';
    var color2 = '#556677';
    var color3 = '#667788';
    var color4 = '#c5c358';
  
    var previousColor = null;
    var currentColor = null;
  
    do {
      // Generate a random number between 1 and 3
      var randomIndex = Math.floor(Math.random() * 4) + 1;
  
      // Assign the color based on the random number
      switch (randomIndex) {
        case 1:
          currentColor = color1;
          break;
        case 2:
          currentColor = color2;
          break;
        case 3:
          currentColor = color3;
          break;
          case 4:
          currentColor = color4;
          break;
          default:
          currentColor = color4;
      }
    } while (currentColor === previousColor); // Repeat until a different color is generated
  
    previousColor = currentColor; // Update the previous color for the next iteration
  
    return currentColor;
  }


  

  return(
    <div id='canvasWrapper'>
        <canvas id='loadingCanvas'  height={345} width={230} ref={canvasRef}></canvas>
    </div>
       

  ); 
}

export default NewLoadingScreen;
