// InputUsername.js
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

function InputUsername({ buttonDisabled, setInputTextfield, fetchData, inputTextField }) {
  const [username, setUsername] = useState('');

  useEffect(() => {
    setUsername(inputTextField);
  }, [inputTextField]);

  const handleInputChange = (event) => {
    setUsername(event.target.value);
    setInputTextfield(event.target.value);
  };

  const handleButtonClick = () => {
    if (username) {
      fetchData(username);
    }
  };

  return (
    <Box marginTop={5}>
      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#FEFB1A',
            },
            '&:hover fieldset': {
              borderColor: '#FEFB1A',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#FEFB1A',
            },
          },
        }}
        style={{ width: '230px', borderColor: 'red' }}
        className="textField"
        label={<span style={{ color: '#abc' }}>User1, User2, List</span>}
        value={username}
        onChange={handleInputChange}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={username.length <= 3 || buttonDisabled}
                edge="end"
                onClick={handleButtonClick}
                sx={{
                  color: '#40BCF4',
                  '&.Mui-disabled': {
                    color: 'gray',
                  },
                }}
              >
                <LocalMoviesIcon />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            style: {
              color: '#abc',
              borderColor: 'red',
              borderWidth: '2px',
            },
          },
        }}
      />
    </Box>
  );
}

export default InputUsername;