// Movie.js

import React, { useEffect, useState } from 'react';
import EmptyPoster from '../images/emptyPoster2.png';
import LoadingScreen from './LoadingScreen';
import PostOnLoad from '../images/initialImage.jpeg';
import serverUnavailableImage from '../images/image.png';

function Movie({
  STATES,
  currentState,
  setCurrentState,
  userData,
  movieName,
  userSlug,
  setButtonDisabled,
  setImageError,
  imageError,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    let newImgSrc = null;

    // Determine the new image source based on the current state
    if (currentState === STATES.FIRSTLOAD) {
      newImgSrc = PostOnLoad;
    } else if (currentState === STATES.FETCHERROR) {
      newImgSrc = serverUnavailableImage;
    } else if (currentState === STATES.USERNOTFOUND) {
      newImgSrc = EmptyPoster;
    } else if (currentState === STATES.LOADING) {
      // Reset states when loading a new image
      setImageLoaded(false);
      setImageError(false);
      newImgSrc = null; // Do not render <img> during loading
    } else if (currentState === STATES.MOVIELOADED) {
      if (userData && userData.data && userData.data.imgsrc) {
        newImgSrc = userData.data.imgsrc;
      } else {
        newImgSrc = EmptyPoster;
      }
    }

    // Update imgSrc only if it has changed
    if (newImgSrc !== imgSrc) {
      setImgSrc(newImgSrc);
      if (newImgSrc !== null) {
        setImageLoaded(false);
        setImageError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState, userData]);

  const handleImageClick = () => {
    if (userSlug && !imageError) {
      const fullUrl = 'https://letterboxd.com' + userSlug;
      const newTab = window.open(fullUrl, '_blank');
      if (newTab) {
        newTab.opener = null;
      }
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setButtonDisabled(false);
    // Only set currentState to MOVIELOADED if no error has occurred
    if (currentState === STATES.LOADING && !imageError) {
      setCurrentState(STATES.MOVIELOADED);
    }
  };

  const handleImageError = () => {
    if (imgSrc !== EmptyPoster) {
      setImgSrc(EmptyPoster); // Set image source to EmptyPoster
      setImageError(true);    // Indicate that the image failed to load
    } else {
      // If EmptyPoster also fails to load, prevent infinite loop
      setImageLoaded(true);
    }
    setButtonDisabled(false);
  };

  const getAltText = () => {
    if (imageError) {
      return 'Image could not be loaded';
    }
    switch (currentState) {
      case STATES.FIRSTLOAD:
        return 'Loading Image';
      case STATES.MOVIELOADED:
        return movieName || 'Movie Poster';
      case STATES.FETCHERROR:
        return 'Server Unavailable';
      case STATES.USERNOTFOUND:
        return 'User Not Found';
      default:
        return 'Movie Poster';
    }
  };

  const showLoadingScreen =
    !imageLoaded &&
    (currentState === STATES.LOADING ||
      currentState === STATES.MOVIELOADED ||
      currentState === STATES.FIRSTLOAD);

  return (
    <div>
      {(imgSrc !== null || showLoadingScreen) && (
        <div className="contentContainer">
          {showLoadingScreen && <LoadingScreen />}
          {imgSrc && (
            <img
              className={`movieImage ${imageLoaded ? 'fade-in' : ''}`}
              src={imgSrc}
              alt={getAltText()}
              onClick={
                currentState === STATES.MOVIELOADED && !imageError
                  ? handleImageClick
                  : null
              }
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Movie;