// App.js
import React, { useRef, useEffect, useState } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import InfoField from './components/InfoField';
import Movie from './components/Movie';
import InputUsername from './components/inputusername';
import Stack from '@mui/material/Stack';
import { Box, TextField } from '@mui/material';

function App() {
  return (
    <Routes>
      <Route path="/:username" element={<MainApp />} />
      <Route path="/" element={<MainApp />} />
    </Routes>
  );
}

function MainApp() {
  const { username } = useParams();
  const navigate = useNavigate();

  const STATES = {
    FIRSTLOAD: 'FIRSTLOAD',
    LOADING: 'LOADING',
    MOVIELOADED: 'MOVIELOADED',
    FETCHERROR: 'FETCHERROR',
    USERNOTFOUND: 'USERNOTFOUND',
  };

  const [inputTextField, setInputTextfield] = useState('');
  const [currentState, setCurrentState] = useState(STATES.FIRSTLOAD);
  const [userData, setUserData] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [movieName, setMovieName] = useState('');
  const [userSlug, setUserSlug] = useState('');
  const [imageError, setImageError] = useState(false);
  const isSSLTrue = window.location.protocol === 'https:';
  const API_URL = isSSLTrue ? process.env.REACT_APP_API_URL_SSL : process.env.REACT_APP_API_URL;
  const loadingRef = useRef(true);  
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (currentState === STATES.FETCHERROR) {
      setInfoText('The service is temporarily unavailable.');
    } else if (currentState === STATES.USERNOTFOUND) {
      setInfoText('The user does not exist or the watchlist is empty.');
    } else if (currentState === STATES.MOVIELOADED) {
      setInfoText(movieName);
    } else if (currentState === STATES.FIRSTLOAD) {
      setInfoText('>RANDOM MOVIE<');
    }
  }, [currentState, movieName, imageError]);

  const eventHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputTextField.length > 3 && !loadingRef.current) {
        fetchData(inputTextField);
      }
    }
  };

  useEffect(() => {
    loadingRef.current = currentState === STATES.LOADING;
  }, [currentState]);

  const fetchData = async (usernameToFetch) => {
    setCurrentState(STATES.LOADING);
    setButtonDisabled(true);
    setImageError(false);
    try {
      const response = await fetch(`${API_URL}v1/${usernameToFetch}`);
      if (!response.ok) {
        setCurrentState(STATES.FETCHERROR);
      } else {
        const data = await response.json();
        if (data.resultCode === 1) {
          setCurrentState(STATES.USERNOTFOUND);
        } else {
          setUserData(data);
          setMovieName(data.data.movieName);
          setUserSlug(data.data.slug);
          setCurrentState(STATES.MOVIELOADED);
        }
      }
    } catch (error) {
      setCurrentState(STATES.FETCHERROR);
    } finally {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', eventHandler);
    return () => {
      window.removeEventListener('keydown', eventHandler);
    };
  }, [inputTextField, loadingRef.current]);



useEffect(() => {
  if (username) {
    setInputTextfield(username);
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData(username);
    }
  }
}, [username]);

  const handleFetchData = (usernameToFetch) => {
    navigate(`/${usernameToFetch}`);
  };

  return (
    <Box textAlign="center" alignContent="center" alignItems="center" alignSelf="center">
      <Stack alignItems="center">
        {/* <div className='header'>L E T T E R T O O L D</div>
        <div className='slogan'>may the movie be with you</div> */}
        <InputUsername
          setInputTextfield={setInputTextfield}
          buttonDisabled={buttonDisabled}
          fetchData={fetchData}
          inputTextField={inputTextField}
        />
        <Movie
          currentState={currentState}
          STATES={STATES}
          setCurrentState={setCurrentState}
          userData={userData}
          movieName={movieName}
          userSlug={userSlug}
          setButtonDisabled={setButtonDisabled}
          setImageError={setImageError}
          imageError={imageError}
        />
        <InfoField content={infoText} />
        <Footer />
      </Stack>
    </Box>
  );
}

export default App;