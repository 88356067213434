import React, { useState, useEffect } from "react";


const InfoField = (props) => {
  const [content, setContent] = useState(props.content);  // Content to display
  const [isFadingOut, setIsFadingOut] = useState(false);  // For controlling the animation

  useEffect(() => {
    if (content !== props.content) {
      setIsFadingOut(true);
      const timer = setTimeout(() => {
        setContent(props.content); 
        setIsFadingOut(false); 
      }, 300); 
      return () => clearTimeout(timer);
    }
  }, [props.content, content]);

  return (
    <div className="infoField">
      <div className={`infoField-content ${isFadingOut ? "fade-out" : ""}`}>
        {content}
      </div>
    </div>
  );
};

export default InfoField;