function Footer(params) {
    return(
        <div className="footer">
            <a 
                href="https://www.endkonsument.art" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}
            >
                   Endkonsument&nbsp;
            </a> 
            &copy;2020 - {new Date().getFullYear()} 
            &nbsp;Lettertoold
        </div>
    );
}

export default Footer;